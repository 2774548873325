.home-container {
  width: 100%;
  display: flex;
  overflow: auto;
  min-height: 100vh;
  align-items: flex-start;
  flex-direction: column;
  justify-content: center;
  background-color: #0d0d0d;
}

.home-container01 {
  width: 100%;
  height: 61px;
  display: flex;
  position: relative;
  align-self: flex-start;
  align-items: flex-start;
  background-color: rgba(85, 138, 67, 0);
}

.home-image {
  width: 35px;
  align-self: center;
  margin-top: 0px;
  object-fit: cover;
  margin-left: 10px;
}

.home-text {
  top: 0px;
  left: 55px;
  color: #ce8b54;
  width: 105px;
  bottom: 0px;
  margin: auto;
  position: absolute;
  font-size: 1.4em;
  align-self: center;
}

.home-link {
  display: contents;
}

.home-text001 {
  top: 0px;
  fill: #8bb743;
  color: #8bb743;
  right: 0px;
  width: 105px;
  bottom: 0px;
  margin: auto;
  position: absolute;
  font-size: 1.4em;
  align-self: center;
  text-decoration: none;
}

.home-container02 {
  width: 100%;
  height: 200px;
  display: flex;
  position: relative;
  align-items: center;
  margin-left: 0%;
  flex-direction: column;
  justify-content: flex-end;
  background-color: #161616;
}

.home-heading {
  top: 0px;
  left: 0px;
  color: #ffffff;
  right: 0px;
  width: 85%;
  bottom: 0px;
  height: auto;
  margin: auto;
  position: absolute;
  font-size: 2.9em;
  align-self: center;
  font-style: normal;
  text-align: center;
  font-weight: 700;
  padding-top: 0px;
  animation-name: fadeInUp;
  animation-delay: 0s;
  animation-duration: 500ms;
  animation-direction: normal;
  animation-iteration-count: 1;
  animation-timing-function: ease;
}

.home-container03 {
  width: 100%;
  height: auto;
  display: flex;
  position: relative;
  align-items: flex-start;
  margin-left: 0px;
  border-color: #161616;
  border-style: dashed;
  border-width: 0px;
  border-radius: var(--dl-radius-radius-radius4);
  flex-direction: column;
  justify-content: flex-end;
  background-color: #161616;
  border-top-left-radius: 0;
  border-top-right-radius: 0x;
}

.home-container04 {
  width: 905px;
  border: 2px dashed rgba(120, 120, 120, 0.4);
  height: 508px;
  display: flex;
  align-self: center;
  margin-top: 20px;
  align-items: center;
  border-color: rgba(120, 120, 120, 0.4);
  border-style: dashed;
  border-width: 0px;
  border-radius: var(--dl-radius-radius-radius4);
  flex-direction: row;
  justify-content: center;
  background-color: rgba(241, 211, 103, 0.69);
  border-top-left-radius: 32px;
  border-top-right-radius: 32px;
  border-bottom-left-radius: 32px;
  border-bottom-right-radius: 32px;
}

.home-link1 {
  display: contents;
}

.home-container05 {
  width: 180px;
  /* height: 52px; */
  padding-top: 12px;
  padding-bottom: 12px;
  display: flex;
  align-self: center;
  margin-top: 20px;
  align-items: center;
  border-color: #734c2d;
  border-style: solid;
  border-width: 2px;
  border-radius: 10px;
  flex-direction: column;
  justify-content: center;
  text-decoration: none;
  background-color: rgb(139, 183, 67);
}

.home-text002 {
  fill: #734c2d;
  color: #734c2d;
  font-style: normal;
  font-weight: 600;
}

.home-text003 {
  color: rgb(255, 255, 255);
  align-self: center;
  margin-top: 40px;
  text-align: center;
  margin-bottom: 40px;
}

.home-container06 {
  width: 100%;
  display: flex;
  margin-top: var(--dl-space-space-unit);
  align-items: center;
  flex-direction: column;
  justify-content: flex-start;
}

.home-container07 {
  width: 1050px;
  display: flex;
  margin-top: 20px;
  align-items: flex-start;
  flex-direction: column;
  justify-content: flex-start;
}

.home-text008 {
  color: rgb(255, 255, 255);
  font-size: 1.7rem;
}

.home-text009 {
  color: rgb(255, 255, 255);
  font-size: 20px;
  font-style: normal;
  margin-top: 10px;
  font-weight: 400;
}

.home-container08 {
  width: 1050px;
  display: flex;
  margin-top: 40px;
  align-items: flex-start;
  flex-direction: column;
  justify-content: flex-start;
}

.home-text020 {
  color: rgb(255, 255, 255);
  font-size: 1.7rem;
}

.home-image1 {
  width: 1050px;
  align-self: center;
  margin-top: 20px;
  object-fit: cover;
}

.home-image2 {
  width: 1050px;
  align-self: center;
  margin-top: 25px;
  object-fit: cover;
}

.home-container09 {
  width: 1050px;
  display: flex;
  margin-top: 20px;
  align-items: flex-start;
  margin-bottom: 20px;
  flex-direction: column;
  justify-content: flex-start;
}

.home-text021 {
  color: rgb(255, 255, 255);
  font-size: 1.7rem;
}

.home-text022 {
  color: rgb(255, 255, 255);
  font-size: 20px;
  font-style: normal;
  margin-top: 10px;
  font-weight: 400;
}

.home-container10 {
  width: 1050px;
  display: flex;
  margin-top: 20px;
  align-items: flex-start;
  margin-bottom: 20px;
  flex-direction: column;
  justify-content: flex-start;
}

.home-text036 {
  color: rgb(255, 255, 255);
  font-size: 1.7rem;
}

.home-text037 {
  color: rgb(255, 255, 255);
  font-size: 20px;
  font-style: normal;
  margin-top: 10px;
  font-weight: 400;
}

.home-container11 {
  flex: 0 0 auto;
  width: 1050;
  height: auto;
  display: flex;
  align-items: flex-start;
  margin-bottom: 30px;
  flex-direction: column;
  justify-content: flex-start;
}

.home-text054 {
  color: rgb(255, 255, 255);
  font-size: 1.8em;
  align-self: center;
  font-style: normal;
  margin-top: 25px;
  font-weight: 600;
  margin-left: 0px;
}

.home-container12 {
  flex: 0 0 auto;
  width: 80%;
  height: auto;
  display: flex;
  margin-top: 25px;
  align-items: flex-start;
  margin-left: 30px;
  border-color: #9a9a9a;
  border-style: dashed;
  border-width: 2px;
  margin-bottom: 15px;
  flex-direction: column;
  justify-content: center;
  border-top-width: 0px;
  border-left-width: 2px;
  border-right-width: 0px;
  border-bottom-width: 0px;
}

.home-text057 {
  color: rgb(255, 255, 255);
  font-size: 1.6rem;
  font-style: normal;
  font-weight: 600;
  margin-left: 20px;
}

.home-text058 {
  color: rgb(255, 255, 255);
  font-size: 17px;
  margin-top: 15px;
  margin-left: 20px;
}

.home-container13 {
  flex: 0 0 auto;
  width: 80%;
  height: auto;
  display: flex;
  margin-top: 25px;
  align-items: flex-start;
  margin-left: 30px;
  border-color: #9a9a9a;
  border-style: dashed;
  border-width: 2px;
  margin-bottom: 15px;
  flex-direction: column;
  justify-content: center;
  border-top-width: 0px;
  border-left-width: 2px;
  border-right-width: 0px;
  border-bottom-width: 0px;
}

.home-text068 {
  color: rgb(255, 255, 255);
  font-size: 1.6rem;
  font-style: normal;
  font-weight: 600;
  margin-left: 20px;
}

.home-text069 {
  color: rgb(255, 255, 255);
  font-size: 17px;
  margin-top: 15px;
  margin-left: 20px;
}

.home-container14 {
  flex: 0 0 auto;
  width: 80%;
  height: auto;
  display: flex;
  margin-top: 25px;
  align-items: flex-start;
  margin-left: 30px;
  border-color: #9a9a9a;
  border-style: dashed;
  border-width: 2px;
  margin-bottom: 15px;
  flex-direction: column;
  justify-content: center;
  border-top-width: 0px;
  border-left-width: 2px;
  border-right-width: 0px;
  border-bottom-width: 0px;
}

.home-text076 {
  color: rgb(255, 255, 255);
  font-size: 1.6rem;
  font-style: normal;
  font-weight: 600;
  margin-left: 20px;
}

.home-text079 {
  color: rgb(255, 255, 255);
  font-size: 17px;
  margin-top: 15px;
  margin-left: 20px;
}

.home-container15 {
  flex: 0 0 auto;
  width: 80%;
  height: auto;
  display: flex;
  margin-top: 25px;
  align-items: flex-start;
  margin-left: 30px;
  border-color: #9a9a9a;
  border-style: dashed;
  border-width: 2px;
  margin-bottom: 15px;
  flex-direction: column;
  justify-content: center;
  border-top-width: 0px;
  border-left-width: 2px;
  border-right-width: 0px;
  border-bottom-width: 0px;
}

.home-text085 {
  color: rgb(255, 255, 255);
  font-size: 1.6rem;
  font-style: normal;
  font-weight: 600;
  margin-left: 20px;
}

.home-text086 {
  color: rgb(255, 255, 255);
  font-size: 17px;
  margin-top: 15px;
  margin-left: 20px;
}

.home-container16 {
  flex: 0 0 auto;
  width: 80%;
  height: auto;
  display: flex;
  margin-top: 25px;
  align-items: flex-start;
  margin-left: 30px;
  border-color: #9a9a9a;
  border-style: dashed;
  border-width: 2px;
  margin-bottom: 15px;
  flex-direction: column;
  justify-content: center;
  border-top-width: 0px;
  border-left-width: 2px;
  border-right-width: 0px;
  border-bottom-width: 0px;
}

.home-text096 {
  color: rgb(255, 255, 255);
  font-size: 1.6rem;
  font-style: normal;
  font-weight: 600;
  margin-left: 20px;
}

.home-text097 {
  color: rgb(255, 255, 255);
  font-size: 17px;
  margin-top: 15px;
  margin-left: 20px;
}

.home-container17 {
  flex: 0 0 auto;
  width: 80%;
  height: auto;
  display: flex;
  margin-top: 25px;
  align-items: flex-start;
  margin-left: 30px;
  border-color: #9a9a9a;
  border-style: dashed;
  border-width: 2px;
  margin-bottom: 15px;
  flex-direction: column;
  justify-content: center;
  border-top-width: 0px;
  border-left-width: 2px;
  border-right-width: 0px;
  border-bottom-width: 0px;
}

.home-text104 {
  color: rgb(255, 255, 255);
  font-size: 1.6rem;
  font-style: normal;
  font-weight: 600;
  margin-left: 20px;
}

.home-text105 {
  color: rgb(255, 255, 255);
  font-size: 17px;
  margin-top: 15px;
  margin-left: 20px;
}

.home-container18 {
  flex: 0 0 auto;
  width: 80%;
  height: auto;
  display: flex;
  margin-top: 25px;
  align-items: flex-start;
  margin-left: 30px;
  border-color: #9a9a9a;
  border-style: dashed;
  border-width: 2px;
  margin-bottom: 15px;
  flex-direction: column;
  justify-content: center;
  border-top-width: 0px;
  border-left-width: 2px;
  border-right-width: 0px;
  border-bottom-width: 0px;
}

.home-text109 {
  color: rgb(255, 255, 255);
  font-size: 1.6rem;
  font-style: normal;
  font-weight: 600;
  margin-left: 20px;
}

.home-text110 {
  color: rgb(255, 255, 255);
  font-size: 17px;
  margin-top: 15px;
  margin-left: 20px;
}

.home-costsection {
  width: 100%;
  height: auot;
  display: flex;
  align-items: flex-start;
  flex-direction: column;
  background-color: #161616;
}

.home-container19 {
  width: 1050px;
  display: flex;
  align-self: center;
  margin-top: 40px;
  align-items: flex-start;
  flex-direction: column;
  justify-content: flex-start;
}

.home-text117 {
  color: rgb(255, 255, 255);
}

.home-text118 {
  color: rgb(255, 255, 255);
  font-size: 20px;
  font-style: normal;
  margin-top: 10px;
  font-weight: 400;
}

.home-image3 {
  width: 0;
  object-fit: cover;
}

.home-image4 {
  width: 1050px;
  align-self: center;
  object-fit: cover;
}

.home-calendleycontainer {
  width: auto;
  display: flex;
  align-self: center;
  margin-top: 40px;
  justify-content: center;
  background-color: #10100e;
}

.home-container20 {
  width: 1111px;
  height: 684px;
  display: flex;
  max-width: var(--dl-size-size-maxwidth);
  align-self: center;
  align-items: center;
  border-radius: var(--dl-radius-radius-radius8);
  justify-content: center;
}

.home-container21 {
  width: 100%;
  height: 100%;
  margin-top: 0px;
  border-radius: var(--dl-radius-radius-radius8);
  margin-bottom: 20px;
  border-top-left-radius: var(--dl-radius-radius-radius8);
  border-top-right-radius: var(--dl-radius-radius-radius8);
}

.home-container22 {
  display: contents;
}

@media(max-width: 991px) {
  .home-heading {
    color: rgb(185, 105, 144);
    font-size: 2.7em;
  }

  .home-container20 {
    align-items: center;
    flex-direction: column;
  }
}

@media(max-width: 767px) {
  .home-heading {
    height: 263px;
  }

  .home-container04 {
    width: 100%;
  }

  .home-calendleycontainer {
    width: 95%;
  }

  .home-container20 {
    padding-left: var(--dl-space-space-twounits);
    padding-right: var(--dl-space-space-twounits);
  }
}

@media(max-width: 479px) {
  .home-container01 {
    width: 100%;
    height: auto;
    padding-top: 5;
    padding-left: 0px;
    padding-right: 0px;
    padding-bottom: 10;
    justify-content: flex-start;
  }

  .home-image {
    align-self: center;
    margin-left: 10px;
  }

  .home-text {
    width: auto;
    font-size: 16px;
    margin-top: 6px;
  }

  .home-text001 {
    top: 0px;
    color: rgb(139, 183, 67);
    right: 15px;
    width: auto;
    bottom: 0px;
    margin-top: 6px;
    position: absolute;
    font-size: 16px;
    align-self: center;
    padding-right: 0;
    text-decoration: none;
  }

  .home-container02 {
    height: 130;
  }

  .home-heading {
    color: #ffffff;
    height: auto;
    font-size: 22px;
    margin-top: auto;
    margin-bottom: 10px;
  }

  .home-container03 {
    height: 280px;
    flex-direction: column;
  }

  .home-container04 {
    width: 320px;
    height: 150px;
    flex-direction: column;
    margin-bottom: 20px;
  }

  .home-container05 {
    margin-top: 20px;
    width: 32%;
    /* height: 35px; */
    padding-top: 5px;
    padding-bottom: 5px;
  }

  .home-text002 {
    font-size: 12px;
  }

  .home-text003 {
    font-size: 15px;
    text-align: center;
    font-weight: 400;
    align-content: center;
  }

  .home-container07 {
    width: 90%;
  }

  .home-text008 {
    font-size: 20px;
    font-weight: 500;
  }

  .home-text009 {
    font-size: 15px;
  }

  .home-container08 {
    width: 95%;
  }

  .home-text020 {
    font-size: 20px;
    font-weight: 500;
  }

  .home-image1 {
    width: 100%;
  }

  .home-image2 {
    width: 100%;
  }

  .home-container09 {
    width: 95%;
  }

  .home-text021 {
    font-size: 20px;
    font-weight: 500;
  }

  .home-text022 {
    font-size: 15px;
  }

  .home-container10 {
    width: 95%;
  }

  .home-text036 {
    font-size: 20px;
    font-weight: 500;
  }

  .home-text037 {
    font-size: 15px;
  }

  .home-container11 {
    width: 80%;
  }

  .home-text054 {
    font-size: 22px;
  }

  .home-text057 {
    font-size: 21px;
  }

  .home-text058 {
    font-size: 15px;
  }

  .home-text068 {
    font-size: 21px;
  }

  .home-text069 {
    font-size: 16px;
  }

  .home-text076 {
    font-size: 21px;
  }

  .home-text079 {
    font-size: 15px;
  }

  .home-text085 {
    font-size: 21px;
  }

  .home-text086 {
    font-size: 15px;
  }

  .home-text096 {
    font-size: 21px;
  }

  .home-text097 {
    font-size: 15px;
  }

  .home-text104 {
    font-size: 21px;
  }

  .home-text105 {
    font-size: 15px;
  }

  .home-text109 {
    font-size: 21px;
  }

  .home-text110 {
    font-size: 15px;
  }

  .home-container19 {
    width: 95%;
  }

  .home-text117 {
    font-size: 20px;
    font-weight: 500;
  }

  .home-text118 {
    font-size: 15px;
  }

  .home-image3 {
    width: 98%;
    margin-left: 5;
  }

  .home-image4 {
    width: 0;
  }

  .home-calendleycontainer {
    width: 95%;
  }

  .home-container20 {
    padding-top: var(--dl-space-space-twounits);
    padding-left: var(--dl-space-space-unit);
    padding-right: var(--dl-space-space-unit);
    padding-bottom: var(--dl-space-space-twounits);
  }
}